// src/components/ServicesSection.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ServicesSection.css';
import webdevelopmenticon from './web-development-icon.svg';
import projectmanagementicon from './project-management-icon.svg';
import erpdevelopmenticon from './erp-development-icon.svg';


function ServicesSection() {
    return (
        <div className="services-section">
            <Container>
                <h3 className="text-center mb-5">Our Services</h3>
                <Row>
                    <Col md={4} className="text-center">
                        <img src={webdevelopmenticon} alt="Web Design" className="service-icon" />
                        <h4>Web Development</h4>
                        <p>Professional web design services to create stunning and responsive websites.</p>
                    </Col>
                    <Col md={4} className="text-center">
                        <img src={projectmanagementicon} alt="Project Management" className="service-icon" />
                        <h4>App Development</h4>
                        <p>Efficient project management solutions to help you streamline your workflow and achieve your goals.</p>
                    </Col>
                    <Col md={4} className="text-center">
                        <img src={erpdevelopmenticon} alt="ERP Development" className="service-icon" />
                        <h4>ERP Development</h4>
                        <p>Custom ERP development services to enhance your business operations and increase productivity.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ServicesSection;
