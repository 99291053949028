import React, { useState, useRef, useEffect } from 'react';
import './ServiceProcess.css';

function ServiceProcess() {
    const processSteps = [
        { title: 'Initial Consultation', description: 'We start by getting to know your business—its goals, challenges, and unique needs. This in-depth consultation allows us to tailor our approach to your specific requirements, ensuring that we align with your budget, timeline, and expectations from the very beginning.' },
        { title: 'Project Proposal', description: 'Based on our consultation, we craft a comprehensive project proposal. This outlines deliverables, milestones, and timelines, detailing the tools and technologies we’ll use—all with clear cost estimates.' },
        { title: 'Contract and Agreement', description: 'To ensure mutual understanding and clear expectations, we draft a contract that details the scope of work, payment terms, and other essential conditions. This agreement serves as a foundation for our collaboration, ensuring both parties are aligned before we proceed.' },
        { title: 'Discovery and Planning', description: 'In this phase, we dive deep into analyzing your current systems and requirements. We collaborate closely with you to develop a detailed project plan, including timelines and milestones, ensuring that every feature and task aligns perfectly with your business objectives.' },
        { title: 'Design and Prototyping', description: 'We bring your ideas to life through detailed wireframes and prototypes, giving you a clear visual of the final product. Our design process is highly collaborative, ensuring every element aligns with your brand and meets your aesthetic and functional preferences.' },
        { title: 'Development', description: 'With the blueprint in hand, we begin the development phase, coding your solution with precision. You\'ll receive regular updates and previews, allowing you to see your project take shape. We\'re always transparent, addressing any changes or issues promptly to keep everything on track.' },
        { title: 'Testing and Quality Assurance', description: 'Before launch, we rigorously test every aspect of your solution to ensure it’s flawless. From cross-device compatibility to bug fixes, we leave no stone unturned. Your feedback during this phase is crucial, allowing us to make any final adjustments for a smooth, error-free deployment.' },
        { title: 'Client Training', description: 'To ensure your team is fully equipped to use and manage your new system, we offer comprehensive training sessions. We also provide detailed documentation, giving you all the tools you need for long-term success.' },
        { title: 'Deployment', description: 'With everything in place, we launch your solution into the live environment. We closely monitor the deployment to ensure everything runs smoothly, quickly addressing any issues that arise during the critical post-launch phase.' },
        { title: 'Post-Launch Support', description: 'Our support doesn’t end at launch. We’re here to assist with any post-launch concerns and offer ongoing maintenance plans to keep your system running at its best. Whether it’s updates, enhancements, or troubleshooting, we’ve got you covered.' },
        { title: 'Project Closure', description: 'We wrap up each project with a thorough review, ensuring all objectives were met and gathering your feedback. This final step includes archiving all documentation and ensuring that all contractual obligations have been fulfilled, setting the stage for future success.' },
    ];

    const [selectedStep, setSelectedStep] = useState(0);
    const carouselRef = useRef(null);

    const nextStep = () => {
        setSelectedStep((prev) => (prev + 1) % processSteps.length);
    };

    const prevStep = () => {
        setSelectedStep((prev) => (prev - 1 + processSteps.length) % processSteps.length);
    };

    useEffect(() => {
        if (carouselRef.current) {
            const activeElement = carouselRef.current.querySelector('.active');
            if (activeElement) {
                const offset = activeElement.offsetLeft - (carouselRef.current.offsetWidth / 2 - activeElement.offsetWidth / 2);
                carouselRef.current.scrollTo({ left: offset, behavior: 'smooth' });
            }
        }
    }, [selectedStep]);

    return (
        <section className="service-process">
            <h2>Our Process</h2>
            <div className="step-content">
                <p>{processSteps[selectedStep].description}</p>
            </div>
            <div className="carousel-container">
                <button onClick={prevStep} className="carousel-arrow left-arrow">&lt;</button>
                <div className="carousel" ref={carouselRef}>
                    {processSteps.map((step, index) => (
                        <div key={index} className={`carousel-step ${index === selectedStep ? 'active' : ''}`}>
                            <h3>{step.title}</h3>
                        </div>
                    ))}
                </div>
                <button onClick={nextStep} className="carousel-arrow right-arrow">&gt;</button>
            </div>
        </section>
    );
}

export default ServiceProcess;
