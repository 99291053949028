// src/components/Services.js

import React from 'react';
import ServicesHeroSection from './ServicesHeroSection';
import CompanyOverviewSection from './CompanyOverviewSection';
import ServicesOverviewSection from './ServicesOverviewSection';
import CallToActionSection from './CallToActionSection';
import ServiceProcess from './ServiceProcess';
import ScrollingItems from './ScrollingItems';
import Footer from './Footer';
import './Services.css';

function ServicesPage() {
    return (
        <div className="services-page">
            <ServicesHeroSection />
            <CompanyOverviewSection />
            <ServicesOverviewSection />
            <CallToActionSection />
            <ServiceProcess />
            <ScrollingItems />
	    <Footer />
        </div>
    );
}

export default ServicesPage;
