// src/components/DemoSection.js
import React from 'react';
import AnalyticsDashboardPreview from './demo/AnalyticsDashboardPreview';
import EmployeeDashboardPreview from './demo/EmployeeDashboardPreview';
import ProjectManagementTool from './demo/ProjectManagementTool';

function DemoSection() {
    return (
        <div>
            <AnalyticsDashboardPreview />
            <EmployeeDashboardPreview />
            <ProjectManagementTool />
            {/* Add more child components as needed */}
        </div>
    );
}

export default DemoSection;
