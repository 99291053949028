import React from 'react';

function ButtonTest() {
  return (
    <div className="button-test">
      <h2>Button Style Test</h2>
      <div style={{ marginBottom: '20px' }}>
        <button>Default Button</button>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <button className="btn-primary">Primary Button</button>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <button disabled>Disabled Button</button>
      </div>
    </div>
  );
}

export default ButtonTest;
