import React, { useState } from 'react';
import './ServicesOverviewSection.css';
import webdevelopmenticon from './web-development-icon.svg';
import projectmanagementicon from './project-management-icon.svg';
import erpdevelopmenticon from './erp-development-icon.svg';

function ServicesOverviewSection() {
    const [selectedService, setSelectedService] = useState('web'); // Default to 'web'

    const renderServiceDetail = () => {
        switch (selectedService) {
            case 'web':
                return (
                    <div className="services-item-detail">
                        <h3>Web Development</h3>
                        <p>
                            Our web development services focus on building high-performing, responsive websites that drive results. 
                            We specialize in SEO optimization, ensuring that your site ranks well on search engines and attracts organic traffic. 
                            With responsive design, your website will look great and function smoothly on any device, from desktops to mobile phones. 
                            Our conversion-focused approach means your website is designed to turn visitors into customers. 
                            We also provide ongoing maintenance and support to keep your site secure and updated.
                        </p>
                    </div>
                );
            case 'app':
                return (
                    <div className="services-item-detail">
                        <h3>App Development</h3>
                        <p>
                            We develop custom applications that integrate seamlessly into your existing workflows, enhancing productivity. 
                            Our apps are tailored to meet your specific business requirements, ensuring they fit perfectly within your operations. 
                            We focus on user-centric design, creating apps that are intuitive and easy to use. 
                            Our apps are also cross-platform compatible, functioning seamlessly across web, mobile, and desktop environments. 
                            We offer continuous integration and deployment, keeping your app relevant, secure, and efficient.
                        </p>
                    </div>
                );
            case 'erp':
                return (
                    <div className="services-item-detail">
                        <h3>ERP Development</h3>
                        <p>
                            Our ERP solutions are designed to streamline your business processes, from inventory management to financial reporting. 
                            We provide tailored ERP systems that meet the specific needs of your business, ensuring they integrate well with your operations. 
                            Our ERP systems bring together various business functions into one cohesive system, enhancing communication and reducing duplication of effort. 
                            With real-time data and reporting, you gain insights into your operations that help you make informed decisions quickly. 
                            Our ERP solutions are scalable, designed to grow with your business as it expands.
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <section className="services-overview">
            <h2>Our Core Services</h2>
            <div className="services-icons">
                <img 
                    src={webdevelopmenticon} 
                    alt="Web Development" 
                    className={`services-icon ${selectedService === 'web' ? 'active' : ''}`}
                    onClick={() => setSelectedService('web')}
                />
                <img 
                    src={projectmanagementicon} 
                    alt="App Development" 
                    className={`services-icon ${selectedService === 'app' ? 'active' : ''}`}
                    onClick={() => setSelectedService('app')}
                />
                <img 
                    src={erpdevelopmenticon} 
                    alt="ERP Development" 
                    className={`services-icon ${selectedService === 'erp' ? 'active' : ''}`}
                    onClick={() => setSelectedService('erp')}
                />
            </div>
            <div className="services-content">
                {renderServiceDetail()}
            </div>
        </section>
    );
}

export default ServicesOverviewSection;
