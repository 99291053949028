import React from 'react';
import companyImage from './CentauriDevLogo.svg';
import './CompanyOverviewSection.css';

function CompanyOverviewSection() {
    return (
        <section id="services-overview" className="services-company-overview">
            <div className="services-company-container">
                <div className="services-company-text">
                    <h2>Our Company</h2>
                    <p>
                        Our company specializes in creating digital solutions that help businesses navigate their next growth phase with confidence.
                        Unlike off-the-shelf software, our solutions are built to fit your business, 
                        eliminating unnecessary features and reducing costs. Whether you're a startup looking to scale, a mid-sized company optimizing operations, or an enterprise seeking to innovate, 
                        our services are designed to align with your specific goals. We provide tools that enhance productivity and drive success. with a focus on seamless integration, efficiency, and user-centric design.
                    </p>
                </div>
                <div className="services-company-visual">
                    <img src={companyImage} alt="Our Company" className="services-company-image"/>
                </div>
            </div>
        </section>
    );
}

export default CompanyOverviewSection;
