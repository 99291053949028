import React, { useState, useEffect } from 'react';
import Airtable from 'airtable';
import './Contact.css';
import background from './background.svg';

// Airtable API setup
const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
const base = new Airtable({ apiKey: apiKey }).base('app2kLogRKdYWeAIi');

function Contact() {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [userData, setUserData] = useState(null);

    const primaryFieldValue = localStorage.getItem('userEmail');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const records = await base('Users').select({
                    filterByFormula: `{Email} = '${primaryFieldValue}'`
                }).firstPage();

                if (records.length > 0) {
                    setUserData(records[0]);
                } else {
                    setError('No user data found');
                }
            } catch (error) {
                setError('Failed to fetch user data');
            }
        };

        if (primaryFieldValue) {
            fetchUserData();
        }
    }, [primaryFieldValue]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const recordData = {
                fields: {
                    Name: name,
                    Message: message,
                }
            };

            if (userData) {
                recordData.fields.Users = [userData.id];
            } else {
                recordData.fields.Email = email;
            }

            await base('tbl4MIriDteNcTn4m').create([recordData]);

            setSuccess('Thank you for contacting us!');
            setError('');
            setName('');
            setMessage('');
            if (!userData) setEmail('');
        } catch (error) {
            setError('Error: ' + error.message);
            setSuccess('');
        }
    };

    const isEmailRequired = !userData || error;

    return (
        <div className="contact-page">
            <div className="background-svg">
                <img src={background} alt="Background" />
            </div>
            <div className="contact-content">
                {/* Contact Form */}
                <div className="contact-form-container">
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        {success && <p className="text-success">{success}</p>}
                        {error && <p className="text-danger">{error}</p>}
                        <div className="form-group">
                            <label>Your Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea
                                className={`form-control ${isEmailRequired ? 'message-small' : 'message'}`}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </div>
                        {isEmailRequired && (
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        )}
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                
                {/* Wrapped text container for smaller breakpoints */}
                <div className="text-wrapper">
                    <div className="contact-text">
                        <p>
                            We're here to support your business's growth and success. Whether you have questions about our services, need assistance with a project, or are looking to explore a potential collaboration, we're ready to help. Please provide as much detail as possible so we can better understand your needs and offer the most effective solutions. Our team will review your message and respond promptly.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
