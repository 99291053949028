// src/components/ProjectManagementTool.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './DemoShared.css'; // Use the shared CSS file
import projectManagementImage from './SVG/ProjectManagementTool.svg';

function ProjectManagementTool() {
    return (
        <Container className="demo-item">
            <Row className="align-items-center">
                <Col md={6} className="demo-text">
                    <h3 className="demo-title">Project Management</h3>
                    <p className="demo-description">
                        Efficiently manage your projects with our comprehensive tools, designed to streamline workflows and boost productivity. Track tasks, manage deadlines, allocate resources, and monitor progress with ease. Our intuitive interfaces and real-time updates ensure you stay on track and within budget, tailored to your specific needs.
                    </p>
                </Col>
                <Col md={6} className="demo-image">
                    <img src={projectManagementImage} alt="Project Management Tool" />
                </Col>
            </Row>
        </Container>
    );
}

export default ProjectManagementTool;
