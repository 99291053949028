// src/components/AnalyticsDashboardPreview.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './DemoShared.css'; // Use the shared CSS file
import analyticsImage from './SVG/EmployeeAnalytics.svg';

function AnalyticsDashboardPreview() {
    return (
        <Container className="demo-item">
            <Row className="align-items-center">
                <Col md={6} className="demo-text">
                    <h3 className="demo-title">Analytics</h3>
                    <p className="demo-description">
                        Unlock valuable insights effortlessly with our advanced analytics dashboards. Transform raw data into actionable intelligence with customizable visuals and real-time updates. Easily track performance, identify trends, and uncover opportunities to drive growth and stay ahead in your industry.
                    </p>
                </Col>
                <Col md={6} className="demo-image">
                    <img src={analyticsImage} alt="Analytics Dashboard" />
                </Col>
            </Row>
        </Container>
    );
}

export default AnalyticsDashboardPreview;
