// src/components/HeroSection.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';
import toolImage from './your-tool-image.svg'; // Import the local image

function HeroSection() {
    return (
        <div className="hero-section">
            <Container className="hero-content">
                <Row className="align-items-center text-center">
                    <Col md={6} className="hero-text-col">
                        <h1 className="hero-title">
                            Transform Your Business With Centauri Dev
                        </h1>
                        <p className="hero-description">Your partner in the digital universe.</p>
                        <a className="hero-button" href="/contact">Get Started Now</a>
                    </Col>
                    <Col md={6}>
                        <img src={toolImage} alt="Tools" className="img-fluid hero-image" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeroSection;
