// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <p>Providing top-notch services to help your business grow and succeed.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-center">
                        <p>&copy; 2024 Centauri Dev. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;
