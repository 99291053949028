import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Services from './components/Services';
import Navbar from './components/Navbar';
import ButtonTest from './components/ButtonTest';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


function App() {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/services" element={<Services />} />
                <Route path ="/buttontest" element={<ButtonTest />} />
            </Routes>
        </>
    );
}

export default App;
