import React from 'react';
import './ServicesHeroSection.css';

function ServicesHeroSection() {
    return (
        <section className="services-hero-section">
            <div className="services-hero-content">
                <h1>Custom Digital Solutions, Tailored to Your Success</h1>
                <p>We build solutions that align perfectly with your business needs - no compromises.</p>
                <a href="#services-overview" className="services-btn-primary">Explore Our Services</a>
            </div>
        </section>
    );
}

export default ServicesHeroSection;
