import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../assets/CentauriDevLogo.svg';
import './Navbar.css';

function CustomNavbar() {
    return (
        <Navbar bg="dark" variant="dark" className="navbar-container">
            <Navbar.Brand href="/">
                <img src={logo} alt="Logo" className="navbar-logo" />
            </Navbar.Brand>
            <Nav className="navbar-links">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
                <Nav.Link href="/services">Services</Nav.Link>
            </Nav>
        </Navbar>
    );
}

export default CustomNavbar;
