// src/components/EmployeeDashboardPreview.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './DemoShared.css'; // Use the shared CSS file
import employeeDashboardImage from './SVG/EmployeeDashboard.svg';

function EmployeeDashboardPreview() {
    return (
        <Container className="demo-item">
            <Row className="align-items-center">
                {/* Reversed order for the image and text */}
                <Col md={{ span: 6, order: 2 }} className="demo-text">
                    <h3 className="demo-title">User Dashboards</h3>
                    <p className="demo-description">
                        Empower your users with personalized, intuitive dashboards. Provide a centralized hub for essential information and tools, tailored to individual roles. Our user-friendly interface ensures easy navigation, enabling efficient task management and real-time progress tracking.
                    </p>
                </Col>
                <Col md={{ span: 6, order: 1 }} className="demo-image">
                    <img src={employeeDashboardImage} alt="Employee Dashboard" />
                </Col>
            </Row>
        </Container>
    );
}

export default EmployeeDashboardPreview;
