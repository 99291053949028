import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './CTASection.css';

function ScheduleADemo() {
    return (
        <div className="cta-section-B">
            <Container>
                <Row className="align-items-center justify-content-center text-center">
                    <Col>
                        <h4 className="cta-title">
                            Schedule A Demo
                        </h4>
                        <Button variant="primary" href="/Contact" className="cta-button">
                            Contact Us
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ScheduleADemo;
