// src/components/Home.js
import React from 'react';
import HeroSection from './HeroSection';
import ServicesSection from './ServicesSection';
// import ValueProposition from './ValueProposition';
import CallToActionSection from './CallToActionSection';
import DemoSection from './DemoSection';
import ScheduleADemo from './ScheduleADemo';
import Footer from './Footer';
import './Home.css';

function Home() {
    return (
        <div className="home">
            <HeroSection />
            <ServicesSection />
            <CallToActionSection />
            <DemoSection />
            <ScheduleADemo />
            <Footer />
        </div>
    );
}

export default Home;
