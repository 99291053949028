import React from 'react';
import './ScrollingItems.css';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

const services = [
    { id: 1, text: "Data Analytics and Insights" },
    { id: 2, text: "IT Strategy Consulting" },
    { id: 3, text: "Tax Strategy and Compliance" },
    { id: 4, text: "Brand Strategy and Development" },
    { id: 5, text: "Cybersecurity Strategy and Risk Management" },
    { id: 6, text: "ERP Strategy and Optimization" },
    { id: 7, text: "Digital Marketing and Growth Strategy" },
    { id: 8, text: "DevOps Optimization and Automation" },
    { id: 9, text: "Business Process Improvement" }, 
    { id: 10, text: "Supply Chain Management" },
];

function ScrollingItems() {
    return (
        <div className="scrolling-section">
            <div className="info-section">
                <h2>Learn more about our other consulting services</h2>
            </div>
            <div className="scrolling-container">
                <div className="scrolling-track">
                    {services.concat(services).concat(services).map((service, index) => (
                        <div key={index} className="scrolling-item">
                            {service.text}
                        </div>
                    ))}
                </div>
            </div>
            <Link to="/contact" className="contact-button">Contact Us</Link>
        </div>
    );
}

export default ScrollingItems;
