import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './CTASection.css';

function CallToActionSection() {
    return (
        <div className="cta-section">
            <Container>
                <Row className="align-items-center justify-content-center text-center">
                    <Col md={12}>
                        <h4 className="cta-title">
                            Ready for Launch?
                        </h4>
                        <Button href="/contact" className="cta-button">
                            Let's Get Started
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CallToActionSection;
